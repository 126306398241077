<template>
  <ValidationObserver>
    <CSVBoxButton
          class="btn btn-primary"
          :licenseKey="csvboxKey"
          :user="uploadMetadata"
          :dynamicColumns="dynamicColumns"
          :onImport="onUploadCompleted"
    >
      {{ buttonTitle || 'Upload Fleet' }}
    </CSVBoxButton>
</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import { CSVBoxButton } from "@csvbox/vuejs";

export default {
  name: "FleetUpload",
  components: { CSVBoxButton },

  props: {
    uniqueId: String,
    buttonTitle: String,
    branchCodes: Array,
    csvboxKey: String
  },

  data() {
    return {
    };
  },

  methods: {
    onUploadCompleted: function (result, data) {
      this.$emit('fleetUploadCompleted', result, data);
    }
  },

  computed: {
    dynamicColumns: function () {
      const options = [];
      this.branchCodes.forEach(bc => {
        options.push({ value: bc, display_label: bc});
      });

      return [{
        column_name: "Location Code",
        display_label: "Location Code",
        type: "list",
        validators: {
          values: options,
          case_sensitive: true
        },
        required: true
      }];
    },

    uploadMetadata: function () {
      return {
        user_id: this.uniqueId, // Used by CSVBox as main identifier
      }
    }
  }
};
</script>
